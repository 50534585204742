<div class="container">
    <div class="contact-wrapper">
        <div class="left-side">
            <h2>{{'Contact us' | translate}}</h2>
            <p class="title" [innerHTML]="'Fill up the form and our Team will get back to you within 24 hours.' | translate"></p>
            <div class="icon-wrapper">
                <img src="assets/images/phone-green.svg" alt="">
                <p>+381 64 16 00 007</p>
            </div>
            <div class="icon-wrapper">
                <img src="assets/images/email-green.svg" alt="">
                <p>info@clickreview.rs</p>
            </div>
            <div class="icon-wrapper">
                <img src="assets/images/location-green.svg" alt="">
                <p class="address">Click Review d.o.o.</p>
                <p class="address-down">Hercegovačka 19, 11000 Beograd</p>
            </div>
            <div class="social-icons">
                <img src="assets/images/facebook-black.svg" alt="">
                <a href="https://www.instagram.com/clickreview.pro" target="_blank"><img src="assets/images/instagram-black.svg" alt=""></a>
            </div>
        </div>
        <div class="right-side" [formGroup]="contactForm" *ngIf="!formSuccess">
            <div class="input-wrapper">
                <p>{{'Your name:' | translate}}</p>
                <input type="text" formControlName="name">
            </div>
            <div class="input-wrapper">
                <p>{{'Your email:' | translate}}</p>
                <input type="text" formControlName="email">
            </div>
            <div class="input-wrapper">
                <p>{{'Your message:' | translate}}</p>
                <textarea name="" id="" cols="40" rows="15" formControlName="message"></textarea>
            </div>
            <div class="button-wrapper">
                <button (click)="send()">{{'SEND' | translate}}</button>
            </div>
        </div>
        <div class="right-side form-success" *ngIf="formSuccess">
            <h4>{{'Thanks for contacting us! We will be in touch with you shortly.' | translate}}</h4>
        </div>
    </div>
</div>